export const surfBreaks = [{
    code: "au-wa-blue-holes",
    region: "Geraldton Coast"
  },
  {
    code: "au-wa-bowes-river",
    region: "Geraldton Coast"
  },
  {
    code: "au-wa-coronation",
    region: "Geraldton Coast"
  },
  {
    code: "au-wa-coronation-beach",
    region: "Geraldton Coast"
  },
  {
    code: "au-wa-drummonds",
    region: "Geraldton Coast"
  },
  {
    code: "au-wa-flat-rocks",
    region: "Geraldton Coast"
  },
  {
    code: "au-wa-geraldton",
    region: "Geraldton Coast"
  },
  {
    code: "au-wa-jakes",
    region: "Geraldton Coast"
  },
  {
    code: "au-wa-red-bluff",
    region: "Gascoyne Coast"
  },
  {
    code: "au-wa-tarcoola",
    region: "Geraldton Coast"
  },
  {
    code: "au-wa-avalon-point",
    region: "Perth Coast"
  },
  {
    code: "au-wa-cable-station-reef",
    region: "Perth Coast"
  },
  {
    code: "au-wa-cottesloe-beach",
    region: "Perth Coast"
  },
  {
    code: "au-wa-ders",
    region: "Perth Coast"
  },
  {
    code: "au-wa-floreat-beach",
    region: "Perth Coast"
  },
  {
    code: "au-wa-observation-city",
    region: "Perth Coast"
  },
  {
    code: "au-wa-salmon-point",
    region: "Perth Coast"
  },
  {
    code: "au-wa-secret-harbour",
    region: "Perth Coast"
  },
  {
    code: "au-wa-south-beach",
    region: "Perth Coast"
  },
  {
    code: "au-wa-stark-bay",
    region: "Perth Coast"
  },
  {
    code: "au-wa-strickland-bay",
    region: "Perth Coast"
  },
  {
    code: "au-wa-the-spot",
    region: "Perth Coast"
  },
  {
    code: "au-wa-trigg-point",
    region: "Perth Coast"
  },
  {
    code: "au-wa-whitfords-beach",
    region: "Perth Coast"
  },
  {
    code: "au-wa-yanchep",
    region: "Perth Coast"
  },
  {
    code: "au-wa-ben-dearg-beach",
    region: "Albany Coast"
  },
  {
    code: "au-wa-cheyne-beach",
    region: "Albany Coast"
  },
  {
    code: "au-wa-hassell-beach",
    region: "Albany Coast"
  },
  {
    code: "au-wa-huzzas",
    region: "Bunbury Geographe Coast"
  },
  {
    code: "au-wa-injidup",
    region: "Bunbury Geographe Coast"
  },
  {
    code: "au-wa-kennedy-beach",
    region: "Esperance Coast"
  },
  {
    code: "au-wa-lights-beach",
    region: "Albany Coast"
  },
  {
    code: "au-wa-lowlands-beach",
    region: "Albany Coast"
  },
  {
    code: "au-wa-margaret-river",
    region: "Leeuwin Coast"
  },
  {
    code: "au-wa-middleton-beach",
    region: "Albany Coast"
  },
  {
    code: "au-wa-ocean-beach",
    region: "Albany Coast"
  },
  {
    code: "au-wa-pea-break",
    region: "Bunbury Geographe Coast"
  },
  {
    code: "au-wa-perkins-beach",
    region: "Albany Coast"
  },
  {
    code: "au-wa-port-geographe-bay",
    region: "Bunbury Geographe Coast"
  },
  {
    code: "au-wa-redgate-peaks",
    region: "Leeuwin Coast"
  },
  {
    code: "au-wa-the-box",
    region: "Leeuwin Coast"
  },
  {
    code: "au-wa-three-bears",
    region: "Bunbury Geographe Coast"
  },
  {
    code: "au-wa-vancouver-beach",
    region: "Albany Coast"
  },
  {
    code: "au-wa-warren-beach",
    region: "Leeuwin Coast"
  },
  {
    code: "au-wa-windmills",
    region: "Bunbury Geographe Coast"
  },
  {
    code: "au-wa-yallingup",
    region: "Bunbury Geographe Coast"
  },
  {
    code: "au-wa-blow-holes",
    region: "Gascoyne Coast"
  },
  {
    code: "au-wa-dunes-surfers-beach",
    region: "Ningaloo Coast"
  },
  {
    code: "au-wa-eighty-mile-beach",
    region: "Pilbara Coast East"
  },
  {
    code: "au-wa-jims-beach",
    region: "Ningaloo Coast"
  },
  {
    code: "au-nt-mindil-beach",
    region: "Darwin Harbour"
  },
  {
    code: "au-sa-caves",
    region: "Upper West Coast"
  },
  {
    code: "au-sa-cheetima-beach",
    region: "Far West Coast"
  },
  {
    code: "au-sa-crushers",
    region: "Upper West Coast"
  },
  {
    code: "au-sa-cunns",
    region: "Upper West Coast"
  },
  {
    code: "au-sa-supertubes",
    region: "Upper West Coast"
  },
  {
    code: "au-sa-witzigs-point-sinclair",
    region: "Upper West Coast"
  },
  {
    code: "au-sa-back-beach-port-neil",
    region: "Spencer Gulf"
  },
  {
    code: "au-sa-back-beach-southend",
    region: "Lower South East Coast"
  },
  {
    code: "au-sa-back-beach-streaky-bay",
    region: "Upper West Coast"
  },
  {
    code: "au-sa-bales-beach",
    region: "South Central Coast"
  },
  {
    code: "au-sa-berry-bay",
    region: "Investigator Strait"
  },
  {
    code: "au-sa-big-conguinar-beach",
    region: "South Central Coast"
  },
  {
    code: "au-sa-broad-beach",
    region: "Gulf St Vincent"
  },
  {
    code: "au-sa-browns-beach",
    region: "Investigator Strait"
  },
  {
    code: "au-sa-bullies",
    region: "Gulf St Vincent"
  },
  {
    code: "au-sa-burdon-beach",
    region: "Lower South East Coast"
  },
  {
    code: "au-sa-butlers-beach",
    region: "Investigator Strait"
  },
  {
    code: "au-sa-cape-kersaint",
    region: "South Central Coast"
  },
  {
    code: "au-sa-chinamans",
    region: "Investigator Strait"
  },
  {
    code: "au-sa-christies-beach",
    region: "Gulf St Vincent"
  },
  {
    code: "au-sa-convention-beach",
    region: "Lower West Coast"
  },
  {
    code: "au-sa-cowleys-beach",
    region: "Spencer Gulf"
  },
  {
    code: "au-sa-dany-beach",
    region: "Investigator Strait"
  },
  {
    code: "au-sa-ethel-wreck",
    region: "Investigator Strait"
  },
  {
    code: "au-sa-farm-beach",
    region: "Lower West Coast"
  },
  {
    code: "au-sa-fishery-beach",
    region: "Gulf St Vincent"
  },
  {
    code: "au-sa-gallipoli-beach",
    region: "Lower West Coast"
  },
  {
    code: "au-sa-geltwood-beach",
    region: "Lower South East Coast"
  },
  {
    code: "au-sa-goldsmith-beach",
    region: "Gulf St Vincent"
  },
  {
    code: "au-sa-greenly-beach",
    region: "Lower West Coast"
  },
  {
    code: "au-sa-gym-beach",
    region: "Investigator Strait"
  },
  {
    code: "au-sa-jolly-beach",
    region: "Investigator Strait"
  },
  {
    code: "au-sa-kangaroo-head",
    region: "South Central Coast"
  },
  {
    code: "au-sa-kiana-beach",
    region: "Lower West Coast"
  },
  {
    code: "au-sa-king-george-beach",
    region: "Investigator Strait"
  },
  {
    code: "au-sa-kings-beach",
    region: "Gulf St Vincent"
  },
  {
    code: "au-sa-ladies-beach",
    region: "Gulf St Vincent"
  },
  {
    code: "au-sa-macs-beach",
    region: "Gulf St Vincent"
  },
  {
    code: "au-sa-mcintyre-beach",
    region: "Lower South East Coast"
  },
  {
    code: "au-sa-middleton-point",
    region: "Gulf St Vincent"
  },
  {
    code: "au-sa-morgans-landing",
    region: "Lower West Coast"
  },
  {
    code: "au-sa-murninnie-beach",
    region: "Spencer Gulf"
  },
  {
    code: "au-sa-myponga-beach",
    region: "Gulf St Vincent"
  },
  {
    code: "au-sa-north-beach",
    region: "Spencer Gulf"
  },
  {
    code: "au-sa-office-beach",
    region: "Spencer Gulf"
  },
  {
    code: "au-sa-parsons-beach-minlaton",
    region: "Spencer Gulf"
  },
  {
    code: "au-sa-parsons-beach-waitpinga",
    region: "Gulf St Vincent"
  },
  {
    code: "au-sa-pebbly-beach",
    region: "Investigator Strait"
  },
  {
    code: "au-sa-pennington-bay",
    region: "South Central Coast"
  },
  {
    code: "au-sa-perlubie-beach",
    region: "Upper West Coast"
  },
  {
    code: "au-sa-picnic-beach-mount-hope",
    region: "Lower West Coast"
  },
  {
    code: "au-sa-pondie",
    region: "Investigator Strait"
  },
  {
    code: "au-sa-port-gawler-beach",
    region: "Gulf St Vincent"
  },
  {
    code: "au-sa-red-banks-beach",
    region: "Central Coast"
  },
  {
    code: "au-sa-salmon-hole",
    region: "Investigator Strait"
  },
  {
    code: "au-sa-sandy-creek",
    region: "South Central Coast"
  },
  {
    code: "au-sa-seaford",
    region: "Gulf St Vincent"
  },
  {
    code: "au-sa-second-beach",
    region: "Spencer Gulf"
  },
  {
    code: "au-sa-sellicks-beach",
    region: "Gulf St Vincent"
  },
  {
    code: "au-sa-sharples-beach",
    region: "Investigator Strait"
  },
  {
    code: "au-sa-shell-beach",
    region: "Investigator Strait"
  },
  {
    code: "au-sa-sheoak-beach",
    region: "Gulf St Vincent"
  },
  {
    code: "au-sa-silver-sands-beach",
    region: "Gulf St Vincent"
  },
  {
    code: "au-sa-simounds-beach",
    region: "Upper West Coast"
  },
  {
    code: "au-sa-snelling-beach",
    region: "Investigator Strait"
  },
  {
    code: "au-sa-southend-beach",
    region: "Lower South East Coast"
  },
  {
    code: "au-sa-spits",
    region: "Investigator Strait"
  },
  {
    code: "au-sa-sugars-beach",
    region: "Gulf St Vincent"
  },
  {
    code: "au-sa-surfers-beach",
    region: "Upper West Coast"
  },
  {
    code: "au-sa-telowie-beach",
    region: "Spencer Gulf"
  },
  {
    code: "au-sa-the-dump",
    region: "Gulf St Vincent"
  },
  {
    code: "au-sa-the-trough",
    region: "Gulf St Vincent"
  },
  {
    code: "au-sa-tiddy-widdy-beach",
    region: "Gulf St Vincent"
  },
  {
    code: "au-sa-trespassers",
    region: "Investigator Strait"
  },
  {
    code: "au-sa-trigs-beach",
    region: "Gulf St Vincent"
  },
  {
    code: "au-sa-tunkalilla-beach",
    region: "Gulf St Vincent"
  },
  {
    code: "au-sa-tyringa-beach",
    region: "Upper West Coast"
  },
  {
    code: "au-sa-waitpinga",
    region: "Gulf St Vincent"
  },
  {
    code: "au-sa-waitpinga-beach",
    region: "Gulf St Vincent"
  },
  {
    code: "au-sa-webb-beach",
    region: "Spencer Gulf"
  },
  {
    code: "au-sa-westcape-beach",
    region: "Investigator Strait"
  },
  {
    code: "au-sa-y-steps",
    region: "Gulf St Vincent"
  },
  {
    code: "au-sa-yanerbie-beach",
    region: "Upper West Coast"
  },
  {
    code: "au-sa-youngs-beach",
    region: "Gulf St Vincent"
  },
  {
    code: "au-sa-almonta-beach",
    region: "Lower West Coast"
  },
  {
    code: "au-sa-barry-beach",
    region: "Lower West Coast"
  },
  {
    code: "au-sa-boston-island",
    region: "Central Coast"
  },
  {
    code: "au-sa-elliston",
    region: "Lower West Coast"
  },
  {
    code: "au-sa-fisheries-bay",
    region: "Central Coast"
  },
  {
    code: "au-sa-gunya-beach",
    region: "Lower West Coast"
  },
  {
    code: "au-sa-homestead-beachs",
    region: "Central Coast"
  },
  {
    code: "au-sa-loch-well-beach",
    region: "Lower West Coast"
  },
  {
    code: "au-sa-mary-ellis-wreck-beach",
    region: "Central Coast"
  },
  {
    code: "au-sa-mcleod-landing",
    region: "Lower West Coast"
  },
  {
    code: "au-sa-mount-camel-beach",
    region: "Lower West Coast"
  },
  {
    code: "au-sa-picnic-beach-lincoln-national-park",
    region: "Central Coast"
  },
  {
    code: "au-sa-squeaky-beach",
    region: "Central Coast"
  },
  {
    code: "au-sa-tinah-beach",
    region: "Central Coast"
  },
  {
    code: "au-sa-venus-bay",
    region: "Upper West Coast"
  },
  {
    code: "au-sa-admella-beach",
    region: "Lower South East Coast"
  },
  {
    code: "au-sa-beachport",
    region: "Lower South East Coast"
  },
  {
    code: "au-sa-brown-beach",
    region: "Lower South East Coast"
  },
  {
    code: "au-sa-matches",
    region: "Lower South East Coast"
  },
  {
    code: "au-sa-outlet-beach",
    region: "Lower South East Coast"
  },
  {
    code: "au-sa-port-macdonnell",
    region: "Lower South East Coast"
  },
  {
    code: "au-sa-stony-rise",
    region: "Lower South East Coast"
  },
  {
    code: "au-vic-anglesea",
    region: "Central Coast"
  },
  {
    code: "au-vic-apollo-bay",
    region: "West Coast"
  },
  {
    code: "au-vic-back-beach",
    region: "Central Coast"
  },
  {
    code: "au-vic-bells-beach",
    region: "Central Coast"
  },
  {
    code: "au-vic-blacknose-point",
    region: "West Coast"
  },
  {
    code: "au-vic-cape-conran",
    region: "East Gippsland Coast"
  },
  {
    code: "au-vic-castle-cove",
    region: "West Coast"
  },
  {
    code: "au-vic-crumpets-beach",
    region: "West Coast"
  },
  {
    code: "au-vic-discovery-bay",
    region: "West Coast"
  },
  {
    code: "au-vic-easter-reef",
    region: "West Coast"
  },
  {
    code: "au-vic-fairhaven",
    region: "Central Coast"
  },
  {
    code: "au-vic-hut-gulley-guvvos",
    region: "Central Coast"
  },
  {
    code: "au-vic-jan-juc",
    region: "Central Coast"
  },
  {
    code: "au-vic-jane-spiers-beach",
    region: "East Gippsland Coast"
  },
  {
    code: "au-vic-johanna",
    region: "West Coast"
  },
  {
    code: "au-vic-kennett-river",
    region: "Central Coast"
  },
  {
    code: "au-vic-lake-tyers",
    region: "East Gippsland Coast"
  },
  {
    code: "au-vic-lorne",
    region: "Central Coast"
  },
  {
    code: "au-vic-mallacoota",
    region: "East Gippsland Coast"
  },
  {
    code: "au-vic-newtons-beach",
    region: "East Gippsland Coast"
  },
  {
    code: "au-vic-ninety-mile-beach",
    region: "Central Gippsland Coast"
  },
  {
    code: "au-vic-ocean-grove",
    region: "Central Coast"
  },
  {
    code: "au-vic-point-addis",
    region: "Central Coast"
  },
  {
    code: "au-vic-point-lonsdale",
    region: "Central Coast"
  },
  {
    code: "au-vic-point-roadknight",
    region: "Central Coast"
  },
  {
    code: "au-vic-port-fairy",
    region: "West Coast"
  },
  {
    code: "au-vic-portsea",
    region: "Central Coast"
  },
  {
    code: "au-vic-princetown",
    region: "West Coast"
  },
  {
    code: "au-vic-quarantines",
    region: "Central Coast"
  },
  {
    code: "au-vic-thirteenth-beach",
    region: "Central Coast"
  },
  {
    code: "au-vic-torquay",
    region: "Central Coast"
  },
  {
    code: "au-vic-torquay-surf-beach",
    region: "Central Coast"
  },
  {
    code: "au-vic-warrnambool",
    region: "West Coast"
  },
  {
    code: "au-vic-wilsons-promontory",
    region: "Central Gippsland Coast"
  },
  {
    code: "au-vic-winkipop",
    region: "Central Coast"
  },
  {
    code: "au-vic-wye-river",
    region: "Central Coast"
  },
  {
    code: "au-vic-angelina-beach",
    region: "Central Coast"
  },
  {
    code: "au-vic-berry-beach",
    region: "Central Coast"
  },
  {
    code: "au-vic-cat-bay",
    region: "Central Coast"
  },
  {
    code: "au-vic-cleeland-bight-beach",
    region: "Central Coast"
  },
  {
    code: "au-vic-cowry-beach",
    region: "Central Coast"
  },
  {
    code: "au-vic-elizabeth-cove",
    region: "Central Coast"
  },
  {
    code: "au-vic-farm",
    region: "Central Coast"
  },
  {
    code: "au-vic-flinders-beach",
    region: "Central Coast"
  },
  {
    code: "au-vic-flynns",
    region: "Central Coast"
  },
  {
    code: "au-vic-forrest-caves",
    region: "Central Coast"
  },
  {
    code: "au-vic-gunnamatta",
    region: "Central Coast"
  },
  {
    code: "au-vic-hen-and-chicken-beach",
    region: "Central Coast"
  },
  {
    code: "au-vic-hutchinson-beach",
    region: "Central Coast"
  },
  {
    code: "au-vic-jesse-island",
    region: "Central Coast"
  },
  {
    code: "au-vic-kitty-miller-bay",
    region: "Central Coast"
  },
  {
    code: "au-vic-magiclands",
    region: "Central Coast"
  },
  {
    code: "au-vic-magiclands-west",
    region: "Central Coast"
  },
  {
    code: "au-vic-new-haven",
    region: "Central Coast"
  },
  {
    code: "au-vic-phillip-island",
    region: "Central Coast"
  },
  {
    code: "au-vic-pines",
    region: "Central Coast"
  },
  {
    code: "au-vic-point-leo",
    region: "Central Coast"
  },
  {
    code: "au-vic-racetrak",
    region: "Central Coast"
  },
  {
    code: "au-vic-red-rock-beach",
    region: "Central Coast"
  },
  {
    code: "au-vic-safety-beach",
    region: "Central Coast"
  },
  {
    code: "au-vic-sheoak-beach",
    region: "Central Coast"
  },
  {
    code: "au-vic-smiths-beach",
    region: "Central Coast"
  },
  {
    code: "au-vic-st-andrews",
    region: "Central Coast"
  },
  {
    code: "au-vic-storm-bay",
    region: "Central Coast"
  },
  {
    code: "au-vic-summerland-bay",
    region: "Central Coast"
  },
  {
    code: "au-vic-surfers-pont",
    region: "Central Coast"
  },
  {
    code: "au-vic-the-colonnades",
    region: "Central Coast"
  },
  {
    code: "au-vic-the-gap",
    region: "Central Coast"
  },
  {
    code: "au-vic-thorny-beach",
    region: "Central Coast"
  },
  {
    code: "au-vic-ventnor-beach",
    region: "Central Coast"
  },
  {
    code: "au-vic-woolamai",
    region: "Central Coast"
  },
  {
    code: "au-vic-woolshed",
    region: "Central Coast"
  },
  {
    code: "au-vic-yacht-club-beach",
    region: "Central Coast"
  },
  {
    code: "au-vic-ycw-beach",
    region: "Central Coast"
  },
  {
    code: "au-nsw-avalon",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-bare-island",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-basin-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-bilgola-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-blackwoods-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-boat-harbor",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-bronte-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-bundeena",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-bungan",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-clontarf-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-clovelly-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-collins-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-congwong-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-coogee-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-cronulla-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-cruwee-cove-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-curl-curl",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-currawong-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-dee-why-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-dee-why-point",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-elephants-back",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-fishermans-beach-collaroy",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-forty-baskets-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-frenchmans-bay",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-freshwater",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-gordons-bay",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-greenhills-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-gunyah-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-horderns-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-jibbon-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-lady-robinsons-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-little-avalon",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-little-bay-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-little-congwong-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-little-makarel-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-little-marley-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-malabar-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-marley-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-mona-vale",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-north-narrabeen",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-north-steyne",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-south-steyne",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-oak-park-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-queenscliff",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-shelly-beach-cronulla",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-silver-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-snapperman-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-south-narrabeen",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-bondi-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-manly",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-maroubra",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-shark-island",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-tamarama-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-the-leap",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-the-peak-newport-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-turimetta-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-wanda-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-warriewood",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-warriewood-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-wattamolla-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-whale-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-winki-pop-fairy-bower",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-yarra-bay",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-7-mile-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-angels-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-ballina",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-belongil-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-beswicks-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-black-rocks",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-bogangar-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-boulder-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-brays-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-broadwater-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-broken-head",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-brunswick-heads",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-byron-bay",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-cabarita",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-casuarina-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-clarks-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-dreamtime-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-evans-head",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-fingal-head",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-hastings-point",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-kings-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-kingscliff",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-lennox-head",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-lighthouse-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-maggies-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-main-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-new-brighton-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-norries-cove",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-patchs-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-pottsville-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-sharpes-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-shelly-beach-ballina",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-skennars-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-south-ballina",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-south-beach-south-ballina",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-south-golden-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-south-kingscliff-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-suffolk-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-tallow-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-the-pass",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-torakina-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-tyagarah-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-wategos",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-whites-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-wooyung-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-avoca-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-barrenjoey-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-birdie-beach",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-box-head",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-budgewoi-beach",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-copacabana",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-crackneck",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-flint-and-steel-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-forresters",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-great-mackarel-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-jenny-dixons-beach",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-killcare-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-lakes-beach",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-lobster-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-macmasters-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-mcmasters-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-norah-head",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-palm-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-patonga",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-putty-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-shelly-beach-central-coast",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-soldiers-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-south-norah-head",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-terrigal-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-tuggerah-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-umina-beach",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-wamberal",
    region: "Sydney Coast"
  },
  {
    code: "au-nsw-barfleur-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-bendalong-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-bherwherre-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-black-point",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-black-rock-aussie-pipe",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-boat-harbour",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-bull-pup",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-burrill-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-caves-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-coneeleys-reef",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-cudmirrah-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-currarong-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-dark-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-depot-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-dolphin-point",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-dum-dums",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-durras-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-emily-miller-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-flat-rock-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-green-island",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-greenfield-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-honeysuckle-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-huskisson-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-kioloa-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-merry-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-mill-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-mollymook-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-monument-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-myrtle-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-narrawallee-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-racecourse-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-rennies-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-richmond-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-steamer-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-surfside-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-sussex-inlet",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-wairo-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-washerwomans-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-werri-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-aragunnu-beach",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-aslings-beach",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-baragoot-beach",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-barlings-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-beares-beach",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-bengello-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-bermagui",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-bingie-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-bournda-beach",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-boydtown-beach",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-brandy-creek",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-brou-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-broulee",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-bulgo-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-bunga-beach",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-burning-palms-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-cocora-beach",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-coila-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-corrigans-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-cowdroys-beach",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-currawulla-beach",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-cuttagee-beach",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-dalmeny-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-denhams-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-duesburys-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-era-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-fullers-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-gillards-beach",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-haycock-beach",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-haywards-beach",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-jiguma-beach",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-kianga-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-lilli-pilli-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-little-garie-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-loader-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-mckenzies-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-merimbula",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-meringo-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-mitchies-beach",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-murrah-beach",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-narooma",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-narooma-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-nelson-beach",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-north-era-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-oaky-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-pambula-beach",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-pebbly-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-penders-beach",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-pinnacles-beach",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-potato-point-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-quondolo-beach",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-rosedale-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-short-point-beach",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-tathra-beach",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-tomakin-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-tura-beach",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-tuross-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-wallaga-beach",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-wallagoot-beach",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-werrong-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-wonboyn-beach",
    region: "Eden Coast"
  },
  {
    code: "au-nsw-yabbara-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-austinmer-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-bellambi-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-bombo",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-bulli-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-coalcliff-reef",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-coledale-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-corrimal-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-culburra-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-easts-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-fairy-meadow-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-fishermans-beach-port-kembla",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-garie-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-illawarra-park",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-jones-beach-kiama-downs",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-kendalls-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-kiama-surf-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-kiyong-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-little-austinmer-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-mccauleys-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-minnamurra-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-mm-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-perkins-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-port-kembla-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-sandon-point-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-scarborough-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-seven-mile-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-sharkies-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-south-beach-windang",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-stanwell-park-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-the-farm",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-thirroul-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-towradgi-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-walkers-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-warilla-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-warrain-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-wimbie-beach",
    region: "Batemans Coast"
  },
  {
    code: "au-nsw-windang-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-wollongong",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-wollongong-north-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-wombarra",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-woonona-beach",
    region: "Illawarra Coast"
  },
  {
    code: "au-nsw-bar-beach-dixon-park",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-blacksmiths-beach",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-bongon-beach",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-dudley-beach",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-frazer-beach",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-ghosties-beach",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-hams-beach",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-horse-shoe-beach",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-merewether-beach",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-middle-camp-beach",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-moonee-beach",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-newcastle",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-nine-mile-beach",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-quarry-beach",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-redhead",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-swansea",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-anna-bay",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-bagnalls-beach",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-blueys",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-boomerang-beach",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-dutchies-beach",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-fingal-beach",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-fiona-beach",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-jimmys-beach",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-mungo-beach",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-number-one-beach",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-one-mile-beach",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-seal-rocks",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-shoal-bay",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-treachery-beach",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-wanderrebah-beach",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-yagon-beach",
    region: "Hunter Coast"
  },
  {
    code: "au-nsw-black-head-beach",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-burgess-beach",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-crowdy-head",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-darawank",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-diamond-beach",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-diamond-head",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-dunbogan-beach",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-flynns-beach",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-forster",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-grants-shore",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-kylies-beach",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-miners-beach",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-nobbys-beach-port-macquarie",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-north-haven",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-north-shore-beach",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-old-bar",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-oxley-beach",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-pilot-beach",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-port-macquarie",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-rainbow-beach",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-red-head-beach",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-rocky-beach",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-saltwater-beach",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-south-beach-dunbogan",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-tuncurry-beach",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-wallabi-beach",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-beilbys-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-boambee-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-bonville-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-coffs-harbour",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-crescent-head",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-diggers-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-front-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-gaagal-wanggaan",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-goolawah-beach",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-grassy-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-hat-head",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-jetty-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-killick-beach",
    region: "Macquarie Coast"
  },
  {
    code: "au-nsw-murrays-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-north-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-north-wall-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-park-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-sawtell",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-scotts-head",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-valla",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-angourie-point",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-arrawarra",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-back-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-blinky-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-brooms-head",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-cabins-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-campbells-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-clarence-river",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-darkum-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-diggers-camp-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-emerald-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-fiddamans-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-frazers-reef",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-harrys",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-jones-beach-barcoongere",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-minnie-water-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-moonee-creek",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-mullaway-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-neds-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-ocean-view-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-opal-cove-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-pipeclay-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-pippi-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-red-rock-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-safety-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-sandon",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-sandys-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-sapphire-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-station-creek-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-ten-mile-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-turners-beach",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-turners-wall",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-woody-head",
    region: "Byron Coast"
  },
  {
    code: "au-nsw-woolgoolga-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-nsw-wooli-beach",
    region: "Coffs Coast"
  },
  {
    code: "au-qld-bilinga-beach",
    region: "Gold Coast"
  },
  {
    code: "au-qld-broadbeach",
    region: "Gold Coast"
  },
  {
    code: "au-qld-burleigh-heads",
    region: "Gold Coast"
  },
  {
    code: "au-qld-currumbin-beach",
    region: "Gold Coast"
  },
  {
    code: "au-qld-currumbin-point",
    region: "Gold Coast"
  },
  {
    code: "au-qld-duranbah",
    region: "Gold Coast"
  },
  {
    code: "au-qld-froggy-beach",
    region: "Gold Coast"
  },
  {
    code: "au-qld-greenmount",
    region: "Gold Coast"
  },
  {
    code: "au-qld-kirra",
    region: "Gold Coast"
  },
  {
    code: "au-qld-kurrawa-beach",
    region: "Gold Coast"
  },
  {
    code: "au-qld-letitia-beach",
    region: "Gold Coast"
  },
  {
    code: "au-qld-main-beach",
    region: "Gold Coast"
  },
  {
    code: "au-qld-miami",
    region: "Gold Coast"
  },
  {
    code: "au-qld-mirage-beach",
    region: "Gold Coast"
  },
  {
    code: "au-qld-narrowneck",
    region: "Gold Coast"
  },
  {
    code: "au-qld-pacific-beach",
    region: "Gold Coast"
  },
  {
    code: "au-qld-palm-beach",
    region: "Gold Coast"
  },
  {
    code: "au-qld-pelican-beach",
    region: "Gold Coast"
  },
  {
    code: "au-qld-snapper-rocks",
    region: "Gold Coast"
  },
  {
    code: "au-qld-surfers-paradise",
    region: "Gold Coast"
  },
  {
    code: "au-qld-tallebudgera-beach",
    region: "Gold Coast"
  },
  {
    code: "au-qld-the-spit",
    region: "Gold Coast"
  },
  {
    code: "au-qld-tugun-beach",
    region: "Gold Coast"
  },
  {
    code: "au-qld-75-mile-beach",
    region: "Fraser Island Coast"
  },
  {
    code: "au-qld-agnes-water",
    region: "Capricornia Coast"
  },
  {
    code: "au-qld-alexandria-bay",
    region: "Sunshine Coast"
  },
  {
    code: "au-qld-boiling-pot",
    region: "Sunshine Coast"
  },
  {
    code: "au-qld-first-point",
    region: "Sunshine Coast"
  },
  {
    code: "au-qld-nationals",
    region: "Sunshine Coast"
  },
  {
    code: "au-qld-johnsons-little-cove",
    region: "Sunshine Coast"
  },
  {
    code: "au-qld-castaways-beach",
    region: "Sunshine Coast"
  },
  {
    code: "au-qld-marcus-beach",
    region: "Sunshine Coast"
  },
  {
    code: "au-qld-cooloola-beach",
    region: "Sunshine Coast"
  },
  {
    code: "au-qld-coolum-beach",
    region: "Sunshine Coast"
  },
  {
    code: "au-qld-coolum-bays",
    region: "Sunshine Coast"
  },
  {
    code: "au-qld-double-island-point",
    region: "Fraser Island Coast"
  },
  {
    code: "au-qld-fraser-island",
    region: "Fraser Island Coast"
  },
  {
    code: "au-qld-granite-bay",
    region: "Sunshine Coast"
  },
  {
    code: "au-qld-noosa-groyne",
    region: "Sunshine Coast"
  },
  {
    code: "au-qld-happys",
    region: "Sunshine Coast"
  },
  {
    code: "au-qld-kawana",
    region: "Sunshine Coast"
  },
  {
    code: "au-qld-kellys-beach",
    region: "Hervey Bay"
  },
  {
    code: "au-qld-long-track",
    region: "Sunshine Coast"
  },
  {
    code: "au-qld-maheno-beach",
    region: "Fraser Island Coast"
  },
  {
    code: "au-qld-manann-beach",
    region: "Hervey Bay"
  },
  {
    code: "au-qld-marcoola-beach",
    region: "Sunshine Coast"
  },
  {
    code: "au-qld-margate-beach",
    region: "Moreton Bay"
  },
  {
    code: "au-qld-maroochydore-beach",
    region: "Sunshine Coast"
  },
  {
    code: "au-qld-moffats",
    region: "Sunshine Coast"
  },
  {
    code: "au-qld-mudjimba-beach",
    region: "Sunshine Coast"
  },
  {
    code: "au-qld-mudjimba-island",
    region: "Sunshine Coast"
  },
  {
    code: "au-qld-nielsens-beach",
    region: "Hervey Bay"
  },
  {
    code: "au-qld-norfolk-beach",
    region: "Gold Coast"
  },
  {
    code: "au-qld-north-stradbroke-island",
    region: "Moreton Bay"
  },
  {
    code: "au-qld-pandanus-beach",
    region: "Moreton Bay"
  },
  {
    code: "au-qld-peregian-creek",
    region: "Sunshine Coast"
  },
  {
    code: "au-qld-stumers-creek",
    region: "Sunshine Coast"
  },
  {
    code: "au-qld-pin-cushion",
    region: "Sunshine Coast"
  },
  {
    code: "au-qld-point-cartwright",
    region: "Sunshine Coast"
  },
  {
    code: "au-qld-queens-beach",
    region: "Moreton Bay"
  },
  {
    code: "au-qld-redcliffe-beach",
    region: "Moreton Bay"
  },
  {
    code: "au-qld-rules-beach",
    region: "Capricornia Coast"
  },
  {
    code: "au-qld-scarborough-beach",
    region: "Moreton Bay"
  },
  {
    code: "au-qld-scotts-point-beach",
    region: "Moreton Bay"
  },
  {
    code: "au-qld-south-stradbroke-island",
    region: "Gold Coast"
  },
  {
    code: "au-qld-spitfire-beach",
    region: "Moreton Bay"
  },
  {
    code: "au-qld-sunrise-beach",
    region: "Sunshine Coast"
  },
  {
    code: "au-qld-sunshine-beach",
    region: "Sunshine Coast"
  },
  {
    code: "au-qld-sylvan-beach",
    region: "Moreton Bay"
  },
  {
    code: "au-qld-tea-tree-bay",
    region: "Sunshine Coast"
  },
  {
    code: "au-qld-teewah-beach",
    region: "Sunshine Coast"
  },
  {
    code: "au-qld-the-bluff",
    region: "Sunshine Coast"
  },
  {
    code: "au-qld-yaroomba",
    region: "Sunshine Coast"
  },
  {
    code: "au-tas-bellbuoy-beach",
    region: "Central North Coast"
  },
  {
    code: "au-tas-bellerive-beach",
    region: "South East Coast"
  },
  {
    code: "au-tas-bicheno",
    region: "Upper East Coast"
  },
  {
    code: "au-tas-boat-harbour-beach",
    region: "Far North West Coast"
  },
  {
    code: "au-tas-cape-sorell",
    region: "Central West Coast"
  },
  {
    code: "au-tas-clifton-beach",
    region: "South East Coast"
  },
  {
    code: "au-tas-cloudy-bay",
    region: "South East Coast"
  },
  {
    code: "au-tas-coal-point",
    region: "South East Coast"
  },
  {
    code: "au-tas-delaneys-beach",
    region: "South East Coast"
  },
  {
    code: "au-tas-denison-beach",
    region: "Upper East Coast"
  },
  {
    code: "au-tas-dianas-beach",
    region: "Upper East Coast"
  },
  {
    code: "au-tas-discovery-beach",
    region: "Central West Coast"
  },
  {
    code: "au-tas-dixons-beach",
    region: "South East Coast"
  },
  {
    code: "au-tas-eaglehawk-neck",
    region: "South East Coast"
  },
  {
    code: "au-tas-east-beach",
    region: "Central North Coast"
  },
  {
    code: "au-tas-east-devonport-beach",
    region: "Central North Coast"
  },
  {
    code: "au-tas-egg-beach",
    region: "South East Coast"
  },
  {
    code: "au-tas-elliott-beach",
    region: "South East Coast"
  },
  {
    code: "au-tas-fairlands-beach",
    region: "Central North Coast"
  },
  {
    code: "au-tas-finns-beach",
    region: "South West Coast"
  },
  {
    code: "au-tas-friendly-beaches",
    region: "Upper East Coast"
  },
  {
    code: "au-tas-gibsons-beach",
    region: "Central North Coast"
  },
  {
    code: "au-tas-greens-beach",
    region: "Far North West Coast"
  },
  {
    code: "au-tas-kingfish-beach",
    region: "South East Coast"
  },
  {
    code: "au-tas-lagoons",
    region: "South East Coast"
  },
  {
    code: "au-tas-north-goats",
    region: "South East Coast"
  },
  {
    code: "au-tas-nutgrove-beach",
    region: "South East Coast"
  },
  {
    code: "au-tas-ocean-beach",
    region: "Central West Coast"
  },
  {
    code: "au-tas-park-beach",
    region: "South East Coast"
  },
  {
    code: "au-tas-rebounds",
    region: "South East Coast"
  },
  {
    code: "au-tas-roaring-beach",
    region: "South East Coast"
  },
  {
    code: "au-tas-roaring-beach-nubeena",
    region: "South East Coast"
  },
  {
    code: "au-tas-robbos-point",
    region: "Central North Coast"
  },
  {
    code: "au-tas-scamander",
    region: "Upper East Coast"
  },
  {
    code: "au-tas-seven-mile-beach",
    region: "South East Coast"
  },
  {
    code: "au-tas-shipstern-bluff",
    region: "South East Coast"
  },
  {
    code: "au-tas-sloping-main",
    region: "South East Coast"
  },
  {
    code: "au-tas-tam-oshanter",
    region: "Central North Coast"
  },
  {
    code: "au-tas-tessos",
    region: "South East Coast"
  },
  {
    code: "au-tas-the-neck",
    region: "South East Coast"
  },
  {
    code: "au-wa-burns-beach",
    region: "Perth Coast"
  },
  {
    code: "au-sa-west-passage",
    region: "Far West Coast"
  },
  {
    code: "au-vic-bay-beach",
    region: "Central Coast"
  },
  {
    code: "au-vic-bore-beach",
    region: "Central Coast"
  },
  {
    code: "au-vic-coal-point-east",
    region: "Central Coast"
  },
  {
    code: "au-vic-coal-point-west",
    region: "Central Coast"
  },
  {
    code: "au-vic-f-break",
    region: "Central Coast"
  },
  {
    code: "au-vic-first-break",
    region: "Central Coast"
  },
  {
    code: "au-vic-griffith-point",
    region: "Central Coast"
  },
  {
    code: "au-vic-inverloch",
    region: "Central Coast"
  },
  {
    code: "au-vic-kilcunda",
    region: "Central Coast"
  },
  {
    code: "au-vic-powlett-river-east",
    region: "Central Coast"
  },
  {
    code: "au-vic-powlett-river-west",
    region: "Central Coast"
  },
  {
    code: "au-vic-punchbowl-beach",
    region: "Central Coast"
  },
  {
    code: "au-vic-san-remo-beach",
    region: "Central Coast"
  },
  {
    code: "au-vic-sandy-waterhole-beach",
    region: "Central Coast"
  },
  {
    code: "au-vic-seaspray",
    region: "Central Gippsland Coast"
  },
  {
    code: "au-vic-second-break",
    region: "Central Coast"
  },
  {
    code: "au-vic-shack-bay",
    region: "Central Coast"
  },
  {
    code: "au-vic-the-oaks",
    region: "Central Coast"
  },
  {
    code: "au-vic-twin-reefs",
    region: "Central Coast"
  },
  {
    code: "au-vic-undertow-bay",
    region: "Central Coast"
  },
  {
    code: "au-vic-williamsons-beach",
    region: "Central Coast"
  },
  {
    code: "au-vic-wonthaggi",
    region: "Central Coast"
  },
  {
    code: "au-vic-wreck-bay",
    region: "Central Coast"
  }
];
