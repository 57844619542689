import Header from '../../Components/Header';

function Unauthorized() {
  return (
    <div className="app">
      <Header>
        <div className="content">
          <h1 style={{fontSize: 80}}>403</h1>
        </div>
      </Header>
      <div className="content block">
        <h2>Unauthorized</h2>
        <a href="/">Go back</a>
      </div>
    </div>
  );
}

export default Unauthorized;
