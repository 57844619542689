import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { surfBreaks } from '../../data/surfBreaks';
import NotFound from '../../Components/NotFound';
import Header from '../../Components/Header';
import AppBadges from '../../Components/AppBadges';

const formatName = (value) => {
  return value = value.replace(/-/g, ' ');
}

const titleCase = (str) => {
  return formatName(str).replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

const mapState = (value) => {
  const stateMap = {
    qld: 'Queensland',
    nsw: 'New South Wales',
    vic: 'Victoria',
    sa: 'South Australia',
    tas: 'Tasmania',
    wa: 'Western Australia',
    nt: 'Northern Territory'
  };

  return stateMap[value] || value;
}

function Spot() {
  const { spot, region, state, country } = useParams();

  useEffect(() => {
    document.title = `${titleCase(spot)} Surf Report & Observations`;
  });

  const validatePathParams = () => {
    const code = `${country}-${state}-${spot}`;

    try {
      const location = surfBreaks.filter(item => item.code === code)[0];
      return titleCase(region) === location.region;
    } catch (err) {
      return false;
    }
  }

  const renderNotificationIcon = () => {
    return (
      <img alt="Surf Alerts Icon" src="/images/app-icon.png" width={35} />
    )
  }

  if (!validatePathParams()) {
    return <NotFound />;
  }

  return (
    <div className="app location">
      <Header>
        <div className="content">
          <h1>{formatName(spot).toUpperCase()}</h1>
          <h2>{`${formatName(region).toUpperCase()} / ${formatName(mapState(state)).toUpperCase()}`}</h2>
        </div>
        <div className="content">
          {<AppBadges width={170} />}
        </div>
      </Header>
      <div className="content block">
        <h2>{`Surf Report ~ Get wind, swell and tide push notifications for ${titleCase(spot)}`}</h2>
      </div>
      <div className="content block">
        <h5>APP FEATURES:</h5>
      </div>
      <div className="content block">
        <h3><a href="/">{`REAL-TIME NOTIFICATIONS`}</a></h3>
        <p>{`Notifications driven by events in real-time. This could be a significant drop in wind or when the tide is spot on for ${titleCase(spot)} and the conditions are looking favourable. Examples:`}</p>
      </div>
      <div className="content code">
        <code>
          {renderNotificationIcon()}
          {formatName(spot).toUpperCase()}<br/>
          ▼ WIND DROP! 6 knots (-8) ↘ NW
        </code>
      </div>
      <div className="content code">
        <code>
          {renderNotificationIcon()}
          {formatName(spot).toUpperCase()}<br/>
          Incoming mid tide (8 knots ↘ NW)
        </code>
      </div>
      <div className="content block">
        <h3><a href="/">{`OBSERVATIONS`}</a></h3>
        <p>{`Observations are sent periodically throughout the day between first and last light for ${titleCase(spot)}. An observation will show the current wind speed, direction and trend (increasing, decreasing or consistent), current wave height, swell direction and period as well as tidal information. Included is a computed 3-point star rating. Example:`}</p>
      </div>
      <div className="content code">
        <code>
          {renderNotificationIcon()}
          {formatName(spot).toUpperCase()} ★★☆<br/>
          ↘ 6 knots NW (-2) Decreasing <br/>
          ↖ 3ft @ 14s NE ~ High tide @ 3.17pm (48%)
        </code>
      </div>
      <div className="content block">
        <h3><a href="/">WIND FORECASTS</a></h3>
        <p>{`Receive wind forecast notifications daily for ${titleCase(spot)} at 5am, 9am and 8pm. If you are subscribed to more than one spot in the ${titleCase(region)} region, these notifications will be grouped. Example:`}</p>
      </div>
      <div className="content code">
        <code>
          {renderNotificationIcon()}
          {formatName(spot).toUpperCase()}<br/>
          Increasing winds forecast this morning<br/>
          ↘ 6 knots SW to ↓ 10 knots S
        </code>
      </div>
    </div>
  );
}

export default Spot;
