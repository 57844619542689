function Header(props) {
  const { children } = props;

  return (
    <header>
      <div className="content">
        <a href="/">
          <img alt="Surf Alerts" src="/images/shaka-white.png" width={30} />
          <span className="appName">SURF ALERTS</span>
        </a>
      </div>
      {children}
    </header>
  );
}

export default Header;
