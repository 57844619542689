import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Header from '../../Components/Header';
import Unauthorized from '../../Components/Unauthorized';
import { useDidMount } from '../../hooks/customHooks';

const apiHost = 'https://api.surfalerts.co';

function Dashboard() {

  const [subscribers, setSubscribers] = useState([]);
  const [subscriberLocations, setSubscriberLocations] = useState([]);
  const [unauthorized, setUnauthorized] = useState(false);
  const [loading, setLoading] = useState(true);

  const { token } = useParams();
  const isMount = useDidMount();

  const getToken = () => {
    if (token) {
      return token;
    }

    return localStorage.getItem('token');
  }

  const headers = () => {
    return {
      'content-type': 'json/application',
      'x-api-key': getToken(),
    };
  }

  const getSubscribers = async () => {
    const result = await fetch(
      `${apiHost}/subscriber?active=true`,
      {
        method: 'GET',
        headers: headers(),
      }
    );

    return await result.json();
  }

  const getSubscriberLocations = async () => {
    const result = await fetch(
      `${apiHost}/subscriber-locations`,
      {
        method: 'GET',
        headers: headers(),
      }
    );

    return await result.json();
  }

  const fetchData = async () => {
    try {
      setSubscribers(await getSubscribers());
      setSubscriberLocations(await getSubscriberLocations());

      localStorage.setItem('token', getToken());
    } catch(err) {
      setUnauthorized(true);
      localStorage.setItem('token', null);
    }

    setLoading(false);
  }

  const subscribersIos = () => (
    subscribers.filter((s) => s.platform === 'ios')
  );

  const subscribersAndroid = () => (
    subscribers.filter((s) => s.platform === 'android')
  );

  const subscribersWithLocations = () => (
    subscribers.filter((s) => s.locations.length > 0)
  );

  const subscribersWithoutLocations = () => (
    subscribers.filter((s) => s.locations.length === 0)
  );

  useEffect(() => {
    if (isMount && subscribers.length === 0) {
      fetchData();
    }
  });

  if (unauthorized) {
    return <Unauthorized />
  }

  const renderPanel = (count, children) => {
    return (
      <div className="panel">
        <h2>{count}</h2>
        <h4>{children}</h4>
      </div>
    );
  }

  subscriberLocations.sort((a,b) => b.subscribers.length - a.subscribers.length);

  return (
    <div className="app dashboard">
      <Header>
        <div className="content">
          <h1 style={{fontSize: 28}}>Dashboard</h1>
        </div>
      </Header>
      {
        !loading && (
          <>
            <div className="content" style={{marginTop: 20}}>
              {renderPanel(subscribers.length, 'active subscribers')}
              {renderPanel(`${Math.round(subscribersWithLocations().length / subscribers.length * 100)}%`, 'with locations')}
              {renderPanel(subscribersIos().length, 'iOS')}
              {renderPanel(subscribersAndroid().length, 'Android')}
            </div>
            <div className="content" style={{marginTop: 50}}>
              {subscriberLocations.map((location) => (
                renderPanel(location.subscribers.length, location.code)
              ))}
            </div>
          </>
        )
      }
    </div>
  );
}

export default Dashboard;
