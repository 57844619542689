import { Routes, Route } from 'react-router-dom';

import './App.css';

import Home from './Components/Home';
import Spot from './Components/Spot';
import PrivacyPolicy from './Components/PrivacyPolicy';
import NotFound from './Components/NotFound';
import Dashboard from './Components/Dashboard';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/:country/:state/:region/:spot" element={<Spot />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/dashboard/:token" element={<Dashboard />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
