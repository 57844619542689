const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
      return 'android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'ios';
  }

  return undefined;
}

const AppBadges = (props) => {
  const { width = 250 } = props;
  const os = getMobileOperatingSystem();

  return (
    <div className="badges">
      {
        os !== 'android' && (
          <div className="badge">
            <a href="https://apps.apple.com/au/app/surf-alerts/id1623303589">
              <img alt="Download on the app store" src="/images/app-store-badge.png" width={width} />
            </a>
          </div>
        )
      }
      {
        os !== 'ios' && (
          <div className="badge">
            <a href="https://play.google.com/store/apps/details?id=com.admgldn.surfalerts">
              <img alt="Download on the play store" src="/images/google-play-badge.png" width={width} />
            </a>
          </div>
        )
      }
    </div>
  );
}

export default AppBadges;
