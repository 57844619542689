import Header from '../../Components/Header';

function NotFound() {
  return (
    <div className="app">
      <Header>
        <div className="content">
          <h1 style={{fontSize: 80}}>404</h1>
        </div>
      </Header>
      <div className="content block">
        <h2>Bummer... that page was not found.</h2>
        <a href="/">Go back</a>
      </div>
    </div>
  );
}

export default NotFound;
