import AppBadges from '../../Components/AppBadges';

function Home() {
  return (
    <div className="app home">
      <div className="wrapper">
        <div className='row'>
          <div className='column left'>
            <div className="content-half">
              <div className="logo">
                <a href="/"><img alt="Surf Alerts" src="/images/shaka-white.png" width={50} /></a>
              </div>
              <div className="header-text">
                <h1>Real time surf<br/> notifications.</h1>
              </div>
              <div className="text">
                <h3>Never miss that window of opportunity.<br /><br /><strong>Get more waves.</strong></h3>
              </div>
              {<AppBadges width={250} />}
            </div>
          </div>
          <div className='column right'>
            <div className="content-half">
              <div className="promo-image">
                <img alt="Real-time surf notifications" src="/images/web-promo.png" width={600}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
